import type { Episode } from '@/types/podcast';

const createAudioElement = (options: { title: string }) => {
  const audio = document.createElement('audio');
  audio.title = options.title;
  audio.preload = 'none';
  audio.autoplay = false;
  audio.controls = true;
  return audio;
};

const events = [
  'audioprocess',
  'canplay',
  'canplaythrough',
  'complete',
  'durationchange',
  'emptied',
  'ended',
  'loadstart',
  'loadeddata',
  'loadedmetadata',
  'pause',
  'play',
  'playing',
  'ratechange',
  'seeked',
  'seeking',
  'stalled',
  'suspend',
  'timeupdate',
  'volumechange',
  'waiting',
] as const;

type AudioPlayerEvents = typeof events[number];

export default class PodcastPlayer {
  #debug = false;
  readyState(): number {
    return this.audio.readyState;
  }
  private readonly audio: HTMLAudioElement;
  private subscribers = new Map<AudioPlayerEvents, (e: Event) => void>();
  private name = '';

  constructor(episode: Episode) {
    this.name = episode.title;
    this.audio = createAudioElement({ title: episode.title });
    this.audio.src = episode.media;
    for (const event of events) {
      this.audio.addEventListener(event, (event) => this.handleEvent(event));
    }
  }

  destroy() {
    for (const event of events) {
      this.audio.addEventListener(event, (event) => this.handleEvent(event));
    }
    this.audio.pause();
  }

  on(event: AudioPlayerEvents, callback: (e: Event) => void) {
    this.subscribers.set(event, callback);
  }

  handleEvent(event: Event) {
    if (this.#debug) {
      console.log(
        new Date().toLocaleTimeString(),
        `event: ${event.type}`,
        this.name
      );
    }
    this.subscribers.get(event.type as AudioPlayerEvents)?.(event);
  }

  networkState() {
    return this.audio.networkState;
  }
  buffered() {
    return this.audio.buffered;
  }
  load() {
    this.audio.load();
  }
  play() {
    return this.audio.play();
  }
  pause() {
    this.audio.pause();
  }
  playing() {
    return !this.audio.paused;
  }
  duration() {
    if (isNaN(this.audio.duration)) return 0;
    return this.audio.duration;
  }
  seek(seek?: number): number {
    if (!seek) return this.audio.currentTime;
    if (seek <= 0 || seek >= this.audio.duration) return this.audio.currentTime;
    this.audio.currentTime = seek;
    return this.audio.currentTime;
  }
  volume(volume?: number) {
    if (!volume) return this.audio.volume;
    return (this.audio.volume = volume);
  }
  mute() {
    this.audio.muted = true;
  }
  unmute() {
    this.audio.muted = false;
  }
  rate(rate?: number) {
    if (!rate) return this.audio.playbackRate;
    return (this.audio.playbackRate = rate);
  }
}
