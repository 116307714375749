import c from 'clsx';
import { createRef } from 'preact';
import { useEffect } from 'preact/hooks';

const ads = {
  desktop: { primary: 'poddtoppen_pano1', secondary: 'poddtoppen_pano2', outstream: 'poddtoppen_outstream-desktop' },
  mobile: { primary: 'poddtoppen_mobil1', secondary: 'poddtoppen_mobil2', outstream: 'poddtoppen_outstream-mobil' },
};
type AdProps = {
  display: 'mobile' | 'desktop';
  type: 'primary' | 'secondary' | 'outstream';
};

function lwhbLoad(el: HTMLElement) {
  if (!el) return;

  window.lwhb?.loaded
    ? window.lwhb.load(el)
    : window.lwhb?.cmd.push(() => window.lwhb?.load(el));
}

export default function Ad({ display, type }: AdProps) {
  const ref = createRef();
  const tid = ads[display][type];

  useEffect(() => {
    const elibleMediaQuery = window.innerWidth > 768 ? 'desktop' : 'mobile';
    if (display === elibleMediaQuery) {
      lwhbLoad(ref.current);
    }
  }, []);

  return (
    <div
      class={c('txs-a js', {
        mb: display === 'mobile',
        dt: display === 'desktop',
      })}
    >
      <div
        ref={ref}
        data-tid={tid}
        data-cnt="true"
        data-pbid="707e4fb2-b22b-4438-9d7a-e8c8ec7bb174"
      />
    </div>
  );
}
