import localStore from 'store';
import { computed, ReadonlySignal } from '@preact/signals';
import { deepSignal } from '@deepsignal/preact';
import TrackPlay from './trackers/trackPlay';
import PodcastPlayer from './audio';
import type { Episode, Podcast } from '~/types/podcast';

const trackProgress = (id: string, seek: number, progress: number) => {
  localStore.set(`p_${id}`, {
    s: seek,
    p: progress,
  });
};

type StorePlayer = {
  state: 'loading' | 'unloaded' | 'buffering' | 'idle' | 'loaded';
  playing: boolean;
  episode: Episode | null;
  podcast: Podcast | null;
  seek: number;
  volume: number;
  mute: boolean;
  duration: number;
  buffered: number;
  rate: number;
};

const data = deepSignal<StorePlayer>({
  state: 'unloaded',
  playing: false,
  episode: null,
  podcast: null,
  rate: 1,
  seek: 0,
  mute: false,
  volume: 1,
  duration: 0,
  buffered: 0,
});

export interface PlayerStore {
  data: typeof data;
  _bufferingLock: boolean;
  _mounted: boolean;
  _tracker?: TrackPlay;
  _audio?: PodcastPlayer;
  resetPlayingState: () => void;
  mount: (episode: Episode, podcast: Podcast, seek?: number) => void;
  play: (episode?: Episode, podcast?: Podcast, seek?: number) => void;
  pause: () => void;
  volume: (level: number) => void;
  seek: (seek: number) => void;
  mute: () => void;
  unmute: () => void;
  rate: (rate: number) => void;
  progress: ReadonlySignal<number>;
  currentTime: ReadonlySignal<string>;
  remaining: ReadonlySignal<string>;
  getAudio: () => PodcastPlayer | undefined;
}

function setMedia(episode: Episode, podcast: Podcast) {
  const mediaSession = navigator.mediaSession;
  if (mediaSession) {
    mediaSession.metadata = new MediaMetadata({
      title: episode.title,
      artist: podcast.title,
      album: podcast.title,
      artwork: [
        {
          src: podcast.images.medium,
          sizes: '300x300',
          type: 'image/jpeg',
        },
      ],
    });
  }
}

export const player: PlayerStore = {
  data,
  _bufferingLock: false,
  _mounted: false,
  _tracker: undefined,
  _audio: undefined,
  resetPlayingState() {
    this.data.state.value = 'unloaded';
    this.data.seek.value = 0;
    this.data.duration.value = 0;
    this.data.buffered.value = 0;
    this.data.rate.value = 1;
  },
  mount(episode: Episode, podcast: Podcast, seek?: number) {
    // todo: Create a better type for playing episode.
    // Merge with podcasts and what we get from the API

    this._audio = new PodcastPlayer(episode);
    this._tracker = new TrackPlay(episode.media, podcast.itunes_id);
    this.data.podcast.value = podcast;
    this.data.episode.value = episode;
    this.data.state.value = 'unloaded';
    this.data.duration.value = this._audio.duration();

    if (seek) {
      this.seek(seek);
    }

    this._audio.on('timeupdate', () => {
      this._bufferingLock = false;
      if (this._audio) {
        const seek = this._audio.seek();
        this.data.seek.value = seek;
      }
      //todo: move this tracking into signal effect
      trackProgress(
        `${this.data.podcast.value?.itunes_id}_${this.data.episode.value?.media}`,
        this.data.seek.value,
        this.progress.value
      );
    });
    this._audio.on('pause', () => {
      this.data.playing.value = false;
    });
    this._audio.on('play', () => {
      this.data.playing.value = true;
    });
    this._audio.on('playing', () => {
      this._bufferingLock = false;
      // logNetworkState(this._controller.networkState());
      // logReadyState(this._controller.readyState());
      this.data.playing.value = true;
    });
    this._audio.on('loadedmetadata', () => {
      if (this._audio) {
        this.data.duration.value = this._audio.duration();
      }
      this._bufferingLock = false;
      this.data.state.value = 'loaded';
    });
    this._audio.on('seeked', () => {
      this._bufferingLock = false;
      this.data.state.value = 'loaded';
    });
    this._audio.on('seeking', () => {
      this._bufferingLock = true;
      // this.data.state.value = 'buffering';
      //todo: move this tracking into signal effect
      trackProgress(
        `${this.data.podcast.value?.itunes_id}_${this.data.episode.value?.media}`,
        this.data.seek.value,
        this.progress.value
      );
      setTimeout(() => {
        if (this._bufferingLock && this.data.playing.value === true) {
          this.data.state.value = 'loading';
        }
      }, 300);
    });
    this._audio.on('stalled', () => {
      // For some reason safari gets stalled event even after canplaythrough
      // has occurred. Cant find any information about this
      // So we are just going to ignore this event for now
      //
      // this._bufferingLock = true;
      // setTimeout(() => {
      //   if (this._bufferingLock) {
      //     this.data.state.value = 'loading';
      //   }
      // }, 300);
    });
    this._audio.on('waiting', () => {
      this._bufferingLock = true;
      setTimeout(() => {
        if (this._bufferingLock) {
          this.data.state.value = 'loading';
        }
      }, 300);
    });
    this._audio.on('canplay', () => {
      this._bufferingLock = false;
      this.data.state.value = 'loaded';
    });
    this._audio.on('canplaythrough', () => {
      this._bufferingLock = false;
      this.data.state.value = 'loaded';
    });
  },
  play(episode?: Episode, podcast?: Podcast, seek?: number) {
    if (episode) {
      if (this._audio) {
        this._audio.destroy();
      }
      this._mounted = false;
      this.resetPlayingState();
      this.data.episode.value = episode;
    } else if (!this._audio) {
      throw new Error('No episode to play');
    }

    if (!this._mounted && episode && podcast) {
      this.mount(episode, podcast, seek);
      this._mounted = true;
    }
    if (seek) {
      this._audio?.seek(seek);
    }

    this._audio?.play().then(() => {
      this._tracker?.start();

      if (this._audio) {
        this.data.duration.value = this._audio.duration();
      }

      if (this.data.episode.value && this.data.podcast.value) {
        setMedia(this.data.episode.value, this.data.podcast.value);
      }
    });
  },
  pause() {
    const audio = this.getAudio();
    if (audio) {
      this._tracker?.pause();
      audio.pause();
    }
  },
  seek(seek: number) {
    const audio = this.getAudio();
    if (audio) {
      audio.seek(seek);
    }
  },
  volume(level: number) {
    const audio = this.getAudio();
    if (audio) {
      this.data.volume.value = audio.volume(level);
    }
  },
  mute() {
    const audio = this.getAudio();
    if (audio) {
      this.data.mute.value = true;
      audio.mute();
    }
  },
  unmute() {
    const audio = this.getAudio();
    if (audio) {
      this.data.mute.value = false;
      audio.unmute();
    }
  },
  rate(rate: number) {
    const audio = this.getAudio();
    if (audio) {
      this.data.rate.value = audio.rate(rate);
    }
  },
  progress: computed(() => {
    if (!data.duration.value) return 0;
    return (data.seek.value / data.duration.value) * 100;
  }),
  currentTime: computed(() => {
    return formatSecondsToTime(data.seek.value);
  }),
  remaining: computed(() => {
    return formatSecondsToTime(data.duration.value - data.seek.value);
  }),
  getAudio() {
    return this._audio;
  },
};

export const getProgress = (episode: { media: string }, podcastId: string) => {
  const data = localStore.get(`p_${podcastId}_${episode.media}`);
  const progress = { progress: 0, currentSeek: 0 };
  if (data) {
    progress.progress = data.p;
    progress.currentSeek = data.s;
  }
  return progress;
};

function formatSecondsToTime(seconds: number) {
  if (seconds <= 0) return '00:00';
  let hours = 0;
  let minutes = 0;
  let sec = 0;

  hours = Math.floor(seconds / (60 * 60));
  minutes = Math.floor((seconds / 60) % 60);
  sec = Math.floor(seconds % 60);

  const time = [];
  // check if leading zero is need
  if (hours > 0 && hours < 10) time.push(`0${hours}`);
  time.push(minutes < 10 ? `0${minutes}` : minutes);
  time.push(sec < 10 ? `0${sec}` : sec);

  return time.join(':');
}

// fu
