import c from 'clsx';
import { PlayIcon, PauseIcon } from './icons';

export function PlayPauseButton(props: {
  loading: boolean;
  playing: boolean;
  onClick: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      class={c(
        props.loading && 'animate-pulse',
        'cursor-pointer will-change-transform transition-transform duration-200 ease-in-out hover:transform-gpu hover:scale-110'
      )}
    >
      {props.playing ? <PauseIcon /> : <PlayIcon />}
    </div>
  );
}
