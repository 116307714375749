import axios from 'axios';

export default class TrackPlay {
  episodeUrl: string | undefined;
  podcastId: string | undefined;
  interval: NodeJS.Timer | undefined;
  accProgress = 0;
  tracked = false;

  constructor(episodeUrl?: string, podcastId?: string) {
    this.episodeUrl = episodeUrl;
    this.podcastId = podcastId;
  }

  track() {
    const track = {
      podcast_id: this.podcastId,
      episode: this.episodeUrl,
      type: 'play',
    };

    axios
      .post('/api/stats', track, { withCredentials: true })
      .catch(console.error);

    this.tracked = true;
    this.destroy();
  }

  pause() {
    this.destroy();
  }

  check() {
    if (this.accProgress > 30) {
      this.track();
      this.accProgress = 0;
    }
  }

  start() {
    if (this.interval || this.tracked) return;

    this.interval = setInterval(() => {
      this.tick();
      this.check();
    }, 1000);
  }

  destroy() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  private tick() {
    this.accProgress += 1;
  }
}
