import type { PlayerStore } from '@/player/store';
import { PlayPauseButton } from './PlayPauseButton';
import { ArrowDownIcon } from './icons';
import Seeker from './Seeker';
import Volume from './Volume';

export function RowPlayer(props: { onClick: () => void; store: PlayerStore }) {
  const player = props.store;

  return (
    <div class="flex flex-col place-items-center bg-black">
      <div class="flex items-center content-center max-w-[75em] w-[90%]">
        <div class="self-center">
          <PlayPauseButton
            loading={player.data.state.value === 'loading'}
            onClick={() => {
              player.data.playing.value ? player.pause() : player.play();
            }}
            playing={player.data.playing.value}
          />
        </div>

        <div class="flex-1 w-full min-h-[3.375rem] mr-[1.875rem] ml-2 sm:mr-10 pt-4 truncate text-ellipsis">
          <p class="text-sm font-bold mb-[3px]">
            {player.data.podcast.value?.title}
          </p>
          <p class="text-xs m-0">{player.data.episode.value?.title}</p>
        </div>

        <div class="hidden lg:block">
          <Volume store={player} />
        </div>

        <ArrowDownIcon onClick={props.onClick} />
      </div>

      <div class="flex px-1 py-1 -mt-2 max-w-[75em] w-[90%] justify-between">
        <div class="-mt-[5px] text-center text-[0.625rem] text-[#ffffffcc]">
          {player.currentTime}
        </div>
        {player.data.duration.value > 0 ? (
          <div class="-mt-[5px] text-center text-[0.625rem] text-[#ffffffcc]">
            <time>-{player.remaining}</time>
          </div>
        ) : null}
      </div>
      <div className="w-[90%]  max-w-[75em] mx-auto">
        <Seeker
          loading={player.data.state.value === 'loading'}
          store={player}
          onSeek={(seek) => player.seek(seek)}
          showScrubberKnob={false}
        />
      </div>
    </div>
  );
}
