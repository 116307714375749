export function Dot() {
  return (
    <div class="absolute top-[30px] -ml-2 bg-primary rounded-full z-50 w-4 h-4 shadow-sm" />
  );
}

export function ArrowDownIcon(props: { onClick: () => void }) {
  return (
    <svg
      onClick={props.onClick}
      class="w-6 h-6 cursor-pointer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 14"
    >
      <path stroke="#fff" stroke-width="2" d="m.7 1.3 12 12m10.6-12-12 12" />
    </svg>
  );
}

export function PlayIcon() {
  return (
    <svg
      fill="currentColor"
      height="40"
      width="40"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" />
    </svg>
  );
}

export function PauseIcon() {
  return (
    <svg
      fill="currentColor"
      height="40"
      width="40"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14H9V8h2v8zm4 0h-2V8h2v8z" />
    </svg>
  );
}

export function RightSeekIcon() {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9986 2V8H14.9986"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.18646 16.8798C2.69691 15.6193 1.65733 13.909 1.22436 12.0064C0.791397 10.1037 0.988495 8.11196 1.78596 6.3311C2.58343 4.55024 3.93806 3.07679 5.64575 2.13276C7.35344 1.18873 9.32168 0.825262 11.2539 1.09712C13.1861 1.36897 14.9777 2.26142 16.3586 3.64L20.9986 8"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function LeftSeekIcon() {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2V8H7"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8121 16.8798C19.3017 15.6193 20.3413 13.909 20.7742 12.0064C21.2072 10.1037 21.0101 8.11196 20.2126 6.3311C19.4152 4.55024 18.0605 3.07679 16.3528 2.13276C14.6451 1.18873 12.6769 0.825262 10.7447 1.09712C8.81245 1.36897 7.02091 2.26142 5.64 3.64L1 8"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
