import c from 'clsx';
import type { ComponentChildren } from 'preact';
import { useMouse } from '~/shared/hooks/use-mouse';
import { useMove } from '~/shared/hooks/use-move';
import { useEffect, useState } from 'preact/hooks';
import { useMergedRef } from '~/shared/hooks/use-merged-ref';
import type { PlayerStore } from '@/player/store';
import { useViewportSize } from '@/shared/hooks/use-viewport-size';

function formatTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

type SeekerProps = {
  showScrubberKnob?: boolean;
  onSeek: (time: number) => void;
  store: PlayerStore;
  loading: boolean;
};

export default function Seeker({ onSeek, loading, store }: SeekerProps) {
  const { data } = store;
  const [seekerWidth, setSeekerWidth] = useState(0);
  const [value, setValue] = useState(0);
  const [waitingForSeek, setWaitingForSeek] = useState(false);
  const { ref, active } = useMove(({ x }) => setValue(x));
  const [dirty, setDirty] = useState(active);
  const { ref: mref, x, active: hover } = useMouse();
  const { width: viewportWidth, height: viewportHeight } = useViewportSize();

  const refs = useMergedRef(ref, mref);

  useEffect(() => {
    if (ref.current) {
      setSeekerWidth(ref.current.offsetWidth);
    }
    // recalculate seeker width on viewport resize
  }, [viewportWidth, viewportHeight]);

  useEffect(() => {
    if (!dirty) {
      setDirty(true);
    }
  }, [active]);

  useEffect(() => {
    setWaitingForSeek(true);
    if (!active && dirty) {
      onSeek(value * data.duration.value);
    }
  }, [value, active, data.duration.value]);

  useEffect(() => {
    if (data.seek.value && waitingForSeek) {
      setWaitingForSeek(false);
    }
  }, [data.seek.value, waitingForSeek]);

  const dVal = active ? value * seekerWidth : x;
  const scrubbing = active && data.duration.value > 0;
  const scrubbingOrHovering = (hover || active) && scrubbing;

  return (
    <div
      ref={refs}
      style={{
        '-webkit-user-drag': 'none',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
      }}
      class={c(
        'max-w-[75rem]  relative cursor-pointer py-2 -my-2 touch-none select-none',
        loading && 'animate-pulse',
        data.duration.value <= 0 && 'pointer-events-none'
      )}
    >
      <div class="bg-white w-full top-0 h-[6px] z-50">
        {scrubbingOrHovering ? (
          <div
            class="absolute -top-full -translate-x-1/2"
            style={{ left: `${(dVal / seekerWidth) * 100}%` }}
          >
            <TimePill>
              {formatTime((dVal / seekerWidth) * data.duration.value)}
            </TimePill>
          </div>
        ) : null}

        <div
          class="absolute h-[6px] bg-primary cursor-pointer"
          style={{
            width: `${
              !store.data.playing ||
              scrubbing ||
              waitingForSeek ||
              data.state.value === 'buffering'
                ? value * 100
                : store.progress
            }%`,
          }}
        />
      </div>
    </div>
  );
}

export function TimePill({ children }: { children: ComponentChildren }) {
  return (
    <div
      class={c(
        'bg-primary  leading-none py-1 px-2 inline-flex items-center',
        'text-[0.5rem] text-white font-mono',
        'rounded-xl relative',
        'after:absolute after:left-1/2 after:-bottom-2',
        'after:-translate-x-1/2',
        'after:bg-transparent after:border-transparent',
        'after:border-solid after:border-r-4 after:border-b-4',
        'after:border-l-4 after:border-t-4 after:border-t-primary',
        'after:h-0 after:w-0'
      )}
    >
      <time>{children}</time>
    </div>
  );
}
