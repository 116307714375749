import { clsx } from 'clsx';
import { createRef, RefObject } from 'preact';
import axios from 'axios';
import Pagination from '../Pagination/Pagination';
import Ad from '../Ad/Ad';
import dayjs from 'dayjs';
import inView from '~/utils/in-view';
import { getProgress, PlayerStore } from '../../player/store';
import { useEffect, useState } from 'preact/hooks';
import { PauseIcon, PlayIcon } from '~/components/Player/icons';
import type { Podcast } from '@/types/podcast';

function ChevronIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#aaaaaa" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

function AdSecondaryAndOutstream() {
  const [renderCount, setRenderCount] = useState(0);
  useEffect(() => {
    setRenderCount((prev) => prev + 1);
  }, []);

  return (
    <>
      <Ad
        display="mobile"
        type={renderCount === 0 ? 'outstream' : 'secondary'}
      />
      <Ad
        display="desktop"
        type={renderCount === 0 ? 'outstream' : 'secondary'}
      />
    </>
  );
}

const scrollEpisodesIntoView = <T extends HTMLElement>(ref: RefObject<T>) => {
  ref.current?.scrollIntoView();
  window.scrollTo(0, window.scrollY - 66);
};

const formatDate = (date: string) =>
  dayjs(date.replace(/(\d{2,4}-\d{1,2}-\d{1,2}).*/, '$1')).format('YYYY-MM-DD');

type EpisodeProps = {
  store: PlayerStore;
  podcast: Podcast;
  title: string;
  header: string;
  podcastUrl: string;
};

async function fetchData(
  podcastId: string,
  limit: number,
  offset: number,
  order: 'asc' | 'desc',
) {
  const { data } = await axios.get(
    `/api/podcasts/${podcastId}/episodes?limit=${limit}&offset=${offset}&order=${order}`,
  );

  return { episodes: data.items, pagination: data.pagination };
}

function useEpisodeQuery({
  podcastId,
  limit,
  offset,
  order = 'desc',
  shouldFetch,
}: {
  shouldFetch: boolean;
  podcastId: string;
  limit: number;
  order: 'asc' | 'desc';
  offset: number;
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ episodes: [], pagination: {} });

  useEffect(() => {
    if (!shouldFetch) return;
    setLoading(true);
    fetchData(podcastId, limit, offset, order).then((data) => {
      setData(data);
      setLoading(false);
    });
  }, [podcastId, limit, offset, order]);

  return { loading, data };
}

const perPage = 10;
export default function Episodes(props: EpisodeProps) {
  const [compInView, setInView] = useState(false);
  const [order, setOrder] = useState<'desc' | 'asc' | null>('desc');
  const [page, setPage] = useState<number>(0);

  const { loading, data } = useEpisodeQuery({
    podcastId: props.podcast.itunes_id,
    limit: perPage,
    offset: page ? page * perPage - perPage : 0,
    order: order || 'desc',
    shouldFetch: true,
  });

  const episodes =
    data?.episodes.length <= 0 ? props.podcast.meta.episodes : data.episodes;

  const player = props.store;

  useEffect(() => {
    inView('.items-grid').on('enter', () => {
      setInView(true);
    });
  }, []);

  const episodesListRef = createRef();
  useEffect(() => {
    if (episodesListRef.current && page > 0) {
      scrollEpisodesIntoView(episodesListRef);
    }
  }, [loading]);

  return (
    <>
      <aside
        class={clsx('episodes items-grid items-grid--will-reveal', {
          'items-grid--in-view': compInView,
        })}
      >
        <div class="flex justify-center">
          <h2 ref={episodesListRef} class="medium-title flex-1">
            {props.header} {props.title}
          </h2>
        </div>

        <div class="flex justify-between border-b border-[#D6D6D6]">
          <span class="text-sm p-px font-bold py-2">
            {order === 'desc' ? 'Senaste' : 'Äldsta'}
          </span>
          <button
            title="Ändra sortering"
            class="mb-1"
            onClick={() => setOrder(order === 'desc' ? 'asc' : 'desc')}
          >
            <div class="rounded-full p-1 bg-black text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M2.24 6.8a.75.75 0 001.06-.04l1.95-2.1v8.59a.75.75 0 001.5 0V4.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0L2.2 5.74a.75.75 0 00.04 1.06zm8 6.4a.75.75 0 00-.04 1.06l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75a.75.75 0 00-1.5 0v8.59l-1.95-2.1a.75.75 0 00-1.06-.04z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </button>
        </div>

        {loading ? (
          <div class="spinner absolute top-1/4 left-1/2 -translate-x-1/2">
            <div class="bounce1" />
            <div class="bounce2" />
            <div class="bounce3" />
          </div>
        ) : null}
        <div
          class={clsx(
            { 'episodes_fade-out': loading },
            'episodes__list episodes_fade',
          )}
        >
          {episodes.map((episode, index) => (
            <div>
              {(index + 1) % 7 === 0 && episodes.length > index + 2 ? (
                <AdSecondaryAndOutstream />
              ) : null}

              <div
                class={clsx('episodes__list__item', {
                  'episode--is-playing': true,
                })}
              >
                <button
                  onClick={() => {
                    player.data.playing.value &&
                    player.data.episode.value?.media === episode.media
                      ? player.pause()
                      : player.play(
                          episode,
                          props.podcast,
                          getProgress(episode, props.podcast.itunes_id)
                            .currentSeek,
                        );
                  }}
                  title={
                    player.data.playing.value &&
                    player.data.episode.value?.media === episode.media
                      ? 'Pausa avsnittet'
                      : 'Spela avsnittet'
                  }
                  type="button"
                  class="episodes__list__playbutton fill-black"
                >
                  {player.data.playing.value &&
                  player.data.episode.value?.media === episode.media ? (
                    <PauseIcon />
                  ) : (
                    <PlayIcon />
                  )}
                </button>

                <a
                  href={getEpisodeUrl(episode, props.podcastUrl)}
                  title={`Avsnitt: ${episode.title}`}
                >
                  <div class="episodes__list__item__top">
                    <h3 class="episodes__list__title">{episode.title}</h3>
                    <div class="episodes__list__item__meta">
                      <time>{formatDate(episode.published)}</time>
                      {episode.duration !== null ? (
                        <time v-if="episode.duration !== null">
                          &#124; {episode.duration}
                        </time>
                      ) : null}
                    </div>
                    <div class="episodes__list__fold">
                      <ChevronIcon />
                    </div>
                  </div>
                  <span
                    class="episodes_progress"
                    style={{
                      width: `${episodeProgress(
                        episode,
                        props.podcast.itunes_id,
                        player,
                      )}%`,
                    }}
                    v-if="episode.progress || current.media === episode.media"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </aside>
      <Pagination
        pagesNeighbour={2}
        pages={Math.floor(
          (data.pagination?.total ?? props.podcast.meta.pagination.total) /
            perPage,
        )}
        currentPage={page || 1}
        goTo={(page) => setPage(page)}
        fetchNext={() => setPage((page || 1) + 1)}
        fetchPrev={() => setPage((page || 1) - 1)}
      />
    </>
  );
}

const getEpisodeUrl = (episode: { slug: string }, podcastUrl: string) =>
  `${podcastUrl}/${episode.slug}`;

const episodeProgress = (
  episode: { media: string },
  podcastId: string,
  playerStore: PlayerStore,
) => {
  if (
    playerStore.data.playing.value &&
    episode.media === playerStore.data.episode.value?.media
  ) {
    return playerStore.progress;
  }
  return getProgress(episode, podcastId).progress;
};
