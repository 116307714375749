import { useEffect, useState } from 'preact/hooks';
import { useHover } from './use-hover';

export function useMouse<T extends HTMLElement = any>() {
  const { ref, hovered } = useHover<T>();
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    active: hovered,
  });

  const setMousePosition = (event: any) => {
    if (ref.current) {
      const rect = event.currentTarget.getBoundingClientRect();

      const x = Math.max(
        0,
        Math.round(
          event.pageX - rect.left - (window.pageXOffset || window.scrollX)
        )
      );

      const y = Math.max(
        0,
        Math.round(
          event.pageY - rect.top - (window.pageYOffset || window.scrollY)
        )
      );

      setPosition({
        x,
        y,
        width: rect.width,
        height: rect.height,
        active: hovered,
      });
    } else {
      setPosition({
        x: event.clientX,
        y: event.clientY,
        width: 0,
        height: 0,
        active: hovered,
      });
    }
  };

  const resetMousePosition = () =>
    setPosition((s) => ({ ...s, active: hovered }));

  useEffect(() => {
    const element = ref?.current ? ref.current : document;
    element.addEventListener('mousemove', setMousePosition as any);
    element.addEventListener('mouseout', resetMousePosition as any);

    return () => {
      element.removeEventListener('mousemove', setMousePosition as any);
      element.removeEventListener('mouseleave', resetMousePosition as any);
    };
  }, [ref.current]);

  return { ref, ...position, active: hovered };
}
