import axios from 'axios';
import c from 'clsx';
import { useEffect, useState } from 'preact/hooks';

const url = (
  id: string,
  { limit, offset }: { limit: number; offset: number }
) => `/api/podcasts/${id}/episodes?limit=${limit}&offset=${offset}`;

const actions = {
  async fetchData(podcastId: string, limit: number, offset: number) {
    const { data } = await axios.get(
      url(podcastId, { offset: offset + limit, limit })
    );

    return { episodes: data.items, pagination: data.pagination };
  },
  async fetchNext(
    podcastId: string,
    pagination: { limit: number; offset: number; total: number }
  ) {
    const { offset, limit, total } = pagination;

    if (offset + limit > total) return;
    return await this.fetchData(podcastId, offset + limit, limit);
  },
  async goToPage(
    page: number,
    podcastId: string,
    pagination: { limit: number; offset: number; total: number }
  ) {
    const { limit } = pagination;
    return await this.fetchData(podcastId, page * limit - limit, limit);
  },
  async fetchPrev(
    podcastId: string,
    pagination: { limit: number; offset: number; total: number }
  ) {
    if (pagination.offset === 0) return;
    const offset = pagination.offset - pagination.limit;
    return await this.fetchData(podcastId, offset, pagination.limit);
  },
};

type Pagination = {
  perPage: number;
  total: number;
  currentPage: number;
};

export function getPagination(args: Pagination) {
  const { perPage, total, currentPage } = args;

  return {
    pages: Math.ceil(total / perPage) + 1,
    offset: (currentPage - 1) * perPage,
    limit: perPage,
  };
}

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const r = [];
  while (i <= to) {
    r.push(i);
    i += step;
  }
  return r;
};

const LEFT_OFFSET = { key: '..', value: -2 };
const RIGHT_OFFSET = { key: '..', value: +2 };

const currentWindow = (
  props: PaginationProps & { currentPage: number; pages: number }
) => {
  const start = 1;
  const SPACING = 3; // LEFT_OFFSET currentPage RIGHT_OFFSET
  const { currentPage, pagesNeighbour, pages: total } = props;
  const padding = Math.min(2, pagesNeighbour);
  const totalNumbers = padding * 2 + SPACING;
  const rangeStart = Math.max(currentPage - padding, start);
  const rangeEnd = Math.min(currentPage + padding, total);

  const pages = range(rangeStart, rangeEnd);

  const leftPadding = currentPage - padding > 2; // we show .. when there is 2 hidden numbers so we dont get 1 .. 2 3
  const rightPadding = total - rangeEnd > 1;

  const leftOvers = 1 + padding * 2 - pages.length;

  if (totalNumbers > total) {
    return range(start, total);
  }

  if (leftPadding && !rightPadding) {
    return [
      start,
      LEFT_OFFSET,
      ...range(rangeStart - leftOvers, rangeStart - 1),
      ...pages,
    ];
  }

  if (!leftPadding && rightPadding) {
    return [
      ...pages,
      ...range(rangeEnd + 1, rangeEnd + leftOvers),
      RIGHT_OFFSET,
      total,
    ];
  }

  return [start, LEFT_OFFSET, ...pages, RIGHT_OFFSET, total];
};

type PaginationProps = {
  pagesNeighbour: number;
  pages: number;
  currentPage: number;
  goTo: (page: number) => void;
  fetchPrev: () => void;
  fetchNext: () => void;
};

export default function Pagination(props: PaginationProps) {
  const w = currentWindow({ ...props });

  return (
    <footer class="episodes__footer">
      {props.currentPage > 1 && (
        <a
          class="button_pagination"
          v-if="currentPage > 1"
          onClick={props.fetchPrev}
        >
          <svg
            width="8"
            height="12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 0l1.41 1.41L2.83 6l4.58 4.59L6 12 0 6l6-6z"
              fill="#FFF"
            />
          </svg>
        </a>
      )}
      {w.map((page) => {
        return (
          <button
            type="button"
            class={c('pagination__num', {
              'pagination__active bg-primary': props.currentPage === page,
              pagination__disabled:
                typeof page === 'object' && page.key === '..',
            })}
            onClick={() => (typeof page === 'number' ? props.goTo(page) : null)}
          >
            {typeof page === 'object' ? page.key : page}
          </button>
        );
      })}
      {props.currentPage < props.pages && (
        <a
          v-if="currentPage < pages"
          class="button_pagination"
          onClick={props.fetchNext}
        >
          <svg
            width="8"
            height="12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 0L.59 1.41 5.17 6 .59 10.59 2 12l6-6-6-6z"
              fill="#FFF"
            />
          </svg>
        </a>
      )}
    </footer>
  );
}
