import type { PlayerStore } from '~/player/store';
import { LeftSeekIcon, RightSeekIcon } from './icons';
import { PlayPauseButton } from './PlayPauseButton';
import Seeker from './Seeker';

export function ExpandedPlayer(props: {
  store: PlayerStore;
  onClick: () => void;
}) {
  return (
    <div class="overlay overflow-y-hidden">
      <div class="overlay__inner">
        <div class="menu-button" onClick={props.onClick}>
          <span>×</span>
        </div>

        <div class="content">
          <div class="media-square media-square--center">
            <img
              class="media-square__src"
              alt="podcast.title"
              srcset={props.store.data.podcast.value?.images.srcset}
              src={props.store.data.podcast.value?.images.medium}
              sizes="auto"
            />
          </div>

          <p class="overlay__title overlay__title--pod">
            {props.store.data.podcast.value?.title}
          </p>
          <p class="overlay__title overlay__title--episode">
            {props.store.data.episode.value?.title}
          </p>

          <div class="player__controls--big">
            <div class="mediabuttons">
              <div class="relative">
                <span
                  class="cursor-pointer"
                  onClick={() =>
                    props.store.seek(props.store.data.seek.value - 15)
                  }
                >
                  <LeftSeekIcon />
                  <div class="absolute text-[9px] top-[7px] left-[5px]">15</div>
                </span>
              </div>
              <div class="playbutton--big">
                <PlayPauseButton
                  loading={props.store.data.state.value === 'loading'}
                  playing={props.store.data.playing.value}
                  onClick={() => {
                    props.store.data.playing.value
                      ? props.store.pause()
                      : props.store.play();
                  }}
                />
              </div>
              <div class="relative">
                <span
                  class="cursor-pointer"
                  onClick={() =>
                    props.store.seek(props.store.data.seek.value + 15)
                  }
                >
                  <RightSeekIcon />
                  <div class="absolute text-[9px] top-[7px] left-[5px]">15</div>
                </span>
              </div>
            </div>

            <div class="meta__time meta__time--passed">
              <time>{props.store.currentTime}</time>
            </div>
            {props.store.data.duration.value > 0 ? (
              <div class="meta__time meta__time--left">
                <time>-{props.store.remaining}</time>
              </div>
            ) : null}

            <div class="seeker">
              <Seeker
                loading={props.store.data.state.value === 'loading'}
                store={props.store}
                onSeek={(seek) => props.store.seek(seek)}
                showScrubberKnob={false}
              />
            </div>
          </div>
        </div>
        <div class="bc">
          <div
            class="cover--blur"
            style={{
              'background-image': `url(${props.store.data.podcast.value?.images.mini})`,
            }}
          />
        </div>
      </div>
    </div>
  );
}
