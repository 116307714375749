import { CSSTransition } from 'preact-transitioning';
import { useState } from 'preact/hooks';
import type { PlayerStore } from '../../player/store';
import { ExpandedPlayer } from './ExpandedPlayer';
import { RowPlayer } from './RowPlayer';

function Player(props: { store: PlayerStore }) {
  const [expanded, setExpand] = useState(false);

  return (
    <>
      <RowPlayer
        store={props.store}
        onClick={() => {
          setExpand(true);
        }}
      />
      <CSSTransition
        in={expanded}
        appear
        classNames="unfold"
        duration={expanded ? 0 : 50}
      >
        <div>
          <ExpandedPlayer
            store={props.store}
            onClick={() => {
              setExpand(false);
            }}
          />
        </div>
      </CSSTransition>
    </>
  );
}

export default Player;
