import c from 'clsx';
import { useEffect, useState } from 'preact/hooks';
import { PlayerStore } from '../../player/store';
import { useMove } from '../../shared/hooks/use-move';

function VolumeIconDown() {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <path d="M18.5 12A4.5 4.5 0 0 0 16 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02zM5 9v6h4l5 5V4L9 9H5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

function VolumeIconMute() {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <path d="M16.5 12A4.5 4.5 0 0 0 14 7.97v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51A8.796 8.796 0 0 0 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06a8.99 8.99 0 0 0 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

function VolumeIconUp() {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

function VolumeIcon({ volume }: { volume: number }) {
  if (volume > 0 && volume < 50) {
    return <VolumeIconDown />;
  }
  if (volume > 50 && volume <= 100) {
    return <VolumeIconUp />;
  }
  if (volume <= 0) {
    return <VolumeIconMute />;
  }
  return <VolumeIconUp />;
}

export default function Volume(props: { class?: string; store: PlayerStore }) {
  const [value, setValue] = useState(props.store.data.volume.value);
  const { ref, active } = useMove(({ x }) => setValue(x));

  useEffect(() => {
    props.store.volume(value);
  }, [value, props.store]);

  return (
    <div class={c('flex h-6 items-center justify-end mr-8', props.class)}>
      <div
        onClick={() =>
          props.store.data.mute.value
            ? props.store.unmute()
            : props.store.mute()
        }
      >
        <VolumeIcon
          volume={
            props.store.data.mute.value
              ? 0
              : props.store.data.volume.value * 100
          }
        />
      </div>
      <div
        ref={ref}
        class="cursor-pointer mx-auto ml-[5px] self-center h-[5px] bg-gray-500 w-[4em] rounded-lg"
        click="updateVolume"
      >
        <div
          style={{
            width: `${value * 100}%`,
          }}
          class="bg-white rounded-lg h-[5px] pointer-events-none"
        />
      </div>
    </div>
  );
}
